import axiosInstance from "../axios";
import { adConstants } from "../utilities/constants";

export const getAdList = () => {
  return async (dispatch) => {
    dispatch({
      type: adConstants.FETCH_AD_REQUEST,
    });

    try {
      const res = await axiosInstance.get("/advertisement/");

      if (res.status === 200) {
        dispatch({
          type: adConstants.FETCH_AD_SUCCESS,
          payload: {
            count: res.data.count,
            adList: res.data.results,
            next: res.data.next,
            previous: res.data.previous,
          },
        });
      } else {
        dispatch({
          type: adConstants.FETCH_AD_FAILURE,
          payload: {
            error: "Failed to fetch ad. " + res.status,
          },
        });
      }
    } catch (e) {
      dispatch({
        type: adConstants.FETCH_AD_FAILURE,
        payload: {
          error: e.message,
        },
      });
    }
  };
};
