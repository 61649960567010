import React, { useEffect, useState } from "react";
import "./style.css";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSideNavList } from "../../actions";

function SideNav(props) {
  const dispatch = useDispatch();
  const sidenav = useSelector((state) => state.sidenav);
  const [rerender, setRerender] = useState(false);

  useEffect(() => {
    dispatch(getSideNavList());
  }, [dispatch]);

  useEffect(() => {}, [sidenav.items]);

  const createSubMenu = (items) => {
    return items.map((_item) => {
      return _item.subHeading ? (
        <SubMenu key={_item.title} title={_item.title}>
          {createSubMenu(_item.subHeading)}
        </SubMenu>
      ) : (
        <MenuItem
          key={_item.title}
          onClick={() => {
            props.mobileView && props.setShowSidebar(false);
            setRerender(!rerender);
          }}
          active={decodeURIComponent(window.location.pathname) === _item.link}
        >
          {_item.title}
          <Link to={_item.link} />
        </MenuItem>
      );
    });
  };

  return (
    <div className={`sidenav ${props.className}`}>
      <ProSidebar>
        {sidenav.items.map((item) => {
          return (
            <Menu iconShape="square" key={item.title}>
              {item.subHeading ? (
                <SubMenu key={item.title} title={"GATE CSE " + item.title}>
                  {createSubMenu(item.subHeading)}
                </SubMenu>
              ) : (
                <MenuItem key={item.title}>
                  {item.title}
                  <Link to={item.link} />
                </MenuItem>
              )}
            </Menu>
          );
        })}
      </ProSidebar>
    </div>
  );
}

export default SideNav;
