import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { getNotesList, setNoteForPreview } from "../../actions";
// import Layout from "../../components/Layout";
import parse from "html-react-parser";
import innerText from "react-innertext";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import { Button } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import "./style.css";
import Helmet from "react-helmet";
import GoogleAd from "../../components/GoogleAd";
import { constTags } from "../../utilities/constants";

function NotesList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { subName, topicName } = useParams();
  const location = useLocation();

  const notesList = useSelector((state) => state.notesList);

  useEffect(() => {
    dispatch(getNotesList(subName, topicName));
  }, [subName, topicName, dispatch]);

  const PaginationButton = () => {
    return (
      <div className="paginationBtnWrapper">
        <Button
          variant="outlined"
          disabled={!notesList.previous}
          onClick={() => {
            dispatch(getNotesList(subName, notesList.previous));
          }}
        >
          <NavigateBeforeIcon /> Prev
        </Button>
        <Button
          variant="outlined"
          disabled={!notesList.next}
          onClick={() => {
            dispatch(getNotesList(subName, notesList.next));
          }}
        >
          Next <NavigateNextIcon />
        </Button>
      </div>
    );
  };

  return (
    <>
      <Helmet>

        <title>Notes: {subName} | {topicName} | GateAtZeal | FreeAtZeal | GATE CSE</title>
        <meta name="description" content={"Gate cse notes; Gate cse " + subName + " notes; gate cse " + topicName + " notes" + constTags} />

      </Helmet>
      <h2>
        Notes: {subName} | {topicName}
      </h2>

      {PaginationButton()}
      {notesList.items.length > 0 ? (
        <div>
          {notesList.items.map((item, idx) => {
            const text = innerText(parse(item.description));
            return (
              <div
                key={item.title}
                className="listItem"
                onClick={() => {
                  dispatch(setNoteForPreview(item, idx));
                  navigate(
                    `/user/notes/preview/${subName}/${topicName}/${item.title}`
                  );
                }}
              >
                <div className="notesTitle">
                  <AutoStoriesIcon className="titleIcon" />
                  <h2 className="textOrange">{item.title}</h2>
                </div>
                <div className="notesContent">
                  {text.substring(0, Math.min(250, text.length))} ...
                </div>

                {/* HorizontalAd2 */}
                {idx === 4 && <GoogleAd slot={"9979608437"} currPath={location.pathname} />}
                {/* HorizontalAd3 */}
                {idx === 10 && <GoogleAd slot={"9385616023"} currPath={location.pathname} />}
              </div>
            );
          })}
        </div>
      ) : (
        <p>No notes found !!!</p>
      )}
      {PaginationButton()}
    </>
  );
}

export default NotesList;
