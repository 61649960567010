import axiosInstance from "../axios";
import { notesPreviewConstants } from "../utilities/constants";

export const setNoteForPreview = (note, idx) => {
  return async (dispatch) => {
    dispatch({
      type: notesPreviewConstants.SET_NOTES_REQUEST,
    });

    try {
      localStorage.setItem("noteIdx", "" + idx);
      dispatch({
        type: notesPreviewConstants.SET_NOTES_SUCCESS,
        payload: {
          item: note,
          idx: idx,
        },
      });
    } catch (e) {
      dispatch({
        type: notesPreviewConstants.SET_NOTES_FAILURE,
        payload: {
          error: "failed to fetch note",
        },
      });
    }
  };
};

export const getSingleNote = (title) => {
  return async (dispatch) => {
    dispatch({
      type: notesPreviewConstants.GET_SINGLE_NOTE_REQUEST,
    });

    try {
      const res = await axiosInstance.get(`/notes?title=${title}`);
      if (res.status === 200) {
        dispatch({
          type: notesPreviewConstants.GET_SINGLE_NOTE_SUCCESS,
          payload: {
            item: res.data.results[0],
            idx: parseInt(localStorage.getItem("noteIdx") || 0),
          },
        });
      } else {
        dispatch({
          type: notesPreviewConstants.GET_SINGLE_NOTE_FAILURE,
          payload: {
            error: "error in fetching note",
          },
        });
      }
    } catch (e) {
      dispatch({
        type: notesPreviewConstants.GET_SINGLE_NOTE_FAILURE,
        payload: {
          item: e.message(),
        },
      });
    }
  };
};
