import React from "react";
// import { Card } from "react-bootstrap";
// import { Button } from "@mui/material";
// import { useSelector } from "react-redux";
// import { Link } from "react-router-dom";
// import Layout from "../../components/Layout";
// import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
// import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import "./style.css";
import Helmet from "react-helmet";
import rankers_2023 from "../../images/rankers_2023.png";
import { constTags } from "../../utilities/constants";

// const Heading = (depth, content) => {
//   switch (depth) {
//     case 1:
//       return <h1 className="heading">{content}</h1>;
//     case 2:
//       return (
//         <h2 style={{ color: "darkorange" }} className="heading">
//           <LocalLibraryIcon /> {content}
//         </h2>
//       );
//     case 3:
//       return (
//         <h3 style={{ color: "brown" }} className="heading">
//           <DoubleArrowIcon /> {content}
//         </h3>
//       );
//     case 4:
//       return <h4 className="heading">{content}</h4>;
//     case 5:
//       return <h5 className="heading">{content}</h5>;
//     default:
//       return;
//   }
// };

function HomePage() {
  // const sidenav = useSelector((state) => state.sidenav);

  // const createCards = (item, depth) => {
  //   return item.length > 0 ? (
  //     item.map((_item) => {
  //       return _item.subHeading ? (
  //         <div key={_item.title}>
  //           {Heading(depth, _item.title)}
  //           <div
  //             className={
  //               _item.subHeading.length > 0 && _item.subHeading[0].subHeading
  //                 ? "container"
  //                 : "container containerLast"
  //             }
  //           >
  //             {createCards(_item.subHeading, depth + 1)}
  //           </div>
  //         </div>
  //       ) : (
  //         <Card key={_item.title} className="mycard">
  //           <Card.Body>
  //             <h4>{_item.title}</h4>
  //             {_item.questionCount >= 0 && (
  //               <p>
  //                 <small>Available Questions: {_item.questionCount}</small>
  //               </p>
  //             )}
  //             <Link to={_item.link}>
  //               <Button variant="contained" color="secondary">
  //                 Go To Practice
  //               </Button>
  //             </Link>
  //           </Card.Body>
  //         </Card>
  //       );
  //     })
  //   ) : (
  //     <div>No data in this category !!</div>
  //   );
  // };

  return (
    <>
      <Helmet>

        <title>FreeAtZeal | Gate@zeal | India’s No. 1 coaching institute for GATE CS/IT</title>
        <meta name="description" content={"Gate at Zeal is India’s No. 1 coaching institute when it comes to gate training in CS/IT. Excellent results in GATE for over 22+ years with more than 2000 selections is leading IITs, NIITs, IIITs, and reputed PSUs." + constTags}  />
      </Helmet>
      {/* {sidenav.items.map((item) => {
        return (
          <div key={item.title}>
            <h1>{item.title}</h1>
            {item.subHeading && (
              <div
                className={
                  item.title +
                  (item.subHeading.length > 0 && item.subHeading[0].subHeading
                    ? "container"
                    : "container containerLast")
                }
              >
                {createCards(item.subHeading, 2)}
              </div>
            )}
          </div>
        );
      })} */}

      <h1 className="Welcome">Welcome To FreeAtZeal !!! </h1>
      <small className="tagline" >A free initiative from <a href="https://gateatzeal.com" >GateAtZeal</a></small>

      <p className="aboutus">
        Gate at Zeal is India’s No. 1 coaching institute when it comes to gate training in CS/IT.
        Our expert panel of teaching staff, steady foundation, advanced resources, and persistent support is what sets us apart from others.
        We ensure to take every possible step to provide our students with the best experience while preparing for Gate and together lead them to success.
        <br />
        Founded in 1998 by <b>Mr. Rajesh Gupta </b> and <b>Mrs. Jaishri Gupta</b>,Gate at Zeal has been offering excellent results in GATE for over <b>22+ years with more than 2000 </b> selections is leading IITs, NIITs, IIITs, and reputed PSUs.
        <br />
        We stand apart from our counterparts because of our unique approach towards GATE training.
        Gate at Zeal believes in quality training with precise and to the point coverage of the GATE syllabus, we do not burden our students with long relentless hours of classes which would leave them exhausted and inefficacious.
        Instead, we train our students with the best resources and comprehensive information which is easy to understand and covers all the topics efficiently and effectively.
        Gate at Zeal is not just a coaching institute but a one-stop solution center for all of our students, we make certain to support them in all their queries and reservations and provide them with post-examination assistance.
        <br />
        Over the past years,Gate at Zeal has produced many toppers in GATE and will continue to deliver outstanding results in the coming years.
      </p>
      <div className="imgWrapper">
        <img src={rankers_2023} alt={"GATE 2022 toppers from zeal"} loading="lazy" />
      </div>

    </>
  );
}

export default HomePage;
