import React, { useEffect } from "react";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { getQuestion } from "../../actions";
import GoogleAd from "../../components/GoogleAd";
import SingleTestQuestion from "../../components/SingleTestQuestion";
import { constTags } from "../../utilities/constants";

function DiscussQuestion() {
  const dispatch = useDispatch();
  const discussQuestion = useSelector((state) => state.discussQuestion);
  const { quesTitle } = useParams();
  const location = useLocation();

  useEffect(() => {
    (function () {
      // DON'T EDIT BELOW THIS LINE
      var d = document,
        s = d.createElement("script");
      s.src = "https://gateatzeal-1.disqus.com/embed.js";
      s.setAttribute("data-timestamp", +new Date());
      (d.head || d.body).appendChild(s);
    })();
    dispatch(getQuestion(decodeURIComponent(quesTitle)));
  }, [dispatch, quesTitle]);

  return (
    <>
      <Helmet>
        <title>
          {discussQuestion.item.question_title &&
            decodeURIComponent(discussQuestion.item.question_title) +
            " GateAtZeal | FreeAtZeal | GATE CSE"}
        </title>
        <meta name="description" content={"discuss question " + discussQuestion.item.question_title &&
          decodeURIComponent(discussQuestion.item.question_title) +" GATEF cs it" + constTags } />
      </Helmet>
      {Object.keys(discussQuestion.item).length > 0 ? (
        <SingleTestQuestion data={discussQuestion.item} discussBtn={false} />
      ) : (
        <p>{discussQuestion.error}</p>
      )}

      {/* HorizontalAd1 */}
      <GoogleAd slot={"7620242247"} currPath={location.pathname} />

      <div id="disqus_thread"></div>
    </>
  );
}

export default DiscussQuestion;
