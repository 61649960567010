export const sidenavConstants = {
  FETCH_NAVLIST_REQUEST: "FETCH_NAVLIST_REQUEST",
  FETCH_NAVLIST_SUCCESS: "FETCH_NAVLIST_SUCCESS",
  FETCH_NAVLIST_FAILURE: "FETCH_NAVLIST_FAILURE",
};

export const notesListConstants = {
  GET_NOTES_LIST_REQUEST: "GET_NOTES_LIST_REQUEST",
  GET_NOTES_LIST_SUCCESS: "GET_NOTES_LIST_SUCCESS",
  GET_NOTES_LIST_FAILURE: "GET_NOTES_LIST_FAILURE",
};

export const questionListConstants = {
  GET_QUESTION_LIST_REQUEST: "GET_QUESTION_LIST_REQUEST",
  GET_QUESTION_LIST_SUCCESS: "GET_QUESTION_LIST_SUCCESS",
  GET_QUESTION_LIST_FAILURE: "GET_QUESTION_LIST_FAILURE",
  CLEAR_REDUCER: "CLEAR_REDUCER",
};

export const discussQuestionConstants = {
  GET_QUESTION_REQUEST: "GET_QUESTION_REQUEST",
  GET_QUESTION_SUCCESS: "GET_QUESTION_SUCCESS",
  GET_QUESTION_FAILURE: "GET_QUESTION_FAILURE",
};

export const notesPreviewConstants = {
  SET_NOTES_REQUEST: "SET_NOTES_REQUEST",
  SET_NOTES_SUCCESS: "SET_NOTES_SUCCESS",
  SET_NOTES_FAILURE: "SET_NOTES_FAILURE",
  GET_SINGLE_NOTE_REQUEST: "GET_SINGLE_NOTE_REQUEST",
  GET_SINGLE_NOTE_SUCCESS: "GET_SINGLE_NOTE_SUCCESS",
  GET_SINGLE_NOTE_FAILURE: "GET_SINGLE_NOTE_FAILURE",
};

export const PAGE_SIZE = 50;

export const adConstants = {
  FETCH_AD_REQUEST: "FETCH_AD_REQUEST",
  FETCH_AD_SUCCESS: "FETCH_AD_SUCCESS",
  FETCH_AD_FAILURE: "FETCH_AD_FAILURE",
};

export const usefulLinkConstants = {
  FETCH_LINK_REQUEST: "FETCH_LINK_REQUEST",
  FETCH_LINK_SUCCESS: "FETCH_LINK_SUCCESS",
  FETCH_LINK_FAILURE: "FETCH_LINK_FAILURE",
};

export const constTags = "online test series,gate cs online test series,gate online test series,gate preparation,gate cse preparation,gate computer science preparation,gate 2023 preparation,gate mock test,gate cs test series,computer science engineering,gate test series,gotestseries,go test series,gate overflow test series,gate overflow,gateoverflow,cs test series,go rank predictor,FCFS Gate question,gate answer keys,IIITH syllabus,gate result,updated answer keys, freeatzeal.com is the best destination for previous years GATE exams Questions with well organized by Year and Chapterwise. While using this site, you agree ...	Practice GATE/ISRO CSE Topic wise previous year question for the selected topic of a subject while preparing for GATE 2023 CSE. GATE CSE Previous year ...Previous year papers of GATE Computer Science and Engineering, Solutions, of previous year GATE CS papers with explanations, subjects wise, topic wise.	General Aptitude Previous Year Questions well organized subject wise, chapter wise and year wise with best solutions, provider freeatzeal.Com.	GATE Question Papers with Solutions: Download year-wise and subject-wise previous year question papers here for your GATE 2023 preparation.	Previous year papers of GATE Computer Science and Engineering, Solutions, ... of previous year GATE CS papers with explanations, topic wise ...	GATE Notes for CSE: Explore GATE CSE (Computer Science & Engineering) study material to learn operating systems, DBMS, theory of computation, and more.	Looking for GATE Computer Science Notes? Stop right here and make the best use of the free GATE Study Material provided by freeatzeal.com	GATE Study Material is a resource that helps GATE aspirants to prepare for the exam. There are plenty of materials available as study materials for GATE ...	The GATE computer science notes are based on important subjects. Check here the list of topic-wise GATE notes of computer science engineering (CS).";