import { notesPreviewConstants } from "../utilities/constants";

const initialState = {
  item: {},
  idx: 0,
  loading: false,
  error: "",
};

const notesPreviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case notesPreviewConstants.SET_NOTES_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case notesPreviewConstants.SET_NOTES_SUCCESS:
      state = {
        ...state,
        loading: false,
        item: action.payload.item,
        idx: action.payload.idx,
      };
      break;
    case notesPreviewConstants.SET_NOTES_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error,
      };
      break;

    case notesPreviewConstants.GET_SINGLE_NOTE_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case notesPreviewConstants.GET_SINGLE_NOTE_SUCCESS:
      state = {
        ...state,
        loading: false,
        item: action.payload.item,
        idx: action.payload.idx,
      };
      break;
    case notesPreviewConstants.GET_SINGLE_NOTE_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error,
      };
      break;
    default:
      break;
  }
  return state;
};

export default notesPreviewReducer;
