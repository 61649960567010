import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { clearQuestionList, getQuestionList } from "../../actions";
// import Layout from "../../components/Layout";
import SingleTestQuestion from "../../components/SingleTestQuestion";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import "./style.css";
import { range } from "../../utilities/helperFunctions";
import { constTags, PAGE_SIZE } from "../../utilities/constants";
import Helmet from "react-helmet";
import GoogleAd from "../../components/GoogleAd";

function QuestionList() {
  const location = useLocation();
  const dispatch = useDispatch();
  const questionList = useSelector((state) => state.questionList);

  const { subName, topicName, years, set } = useParams();
  const [module, category] = location.pathname.split("/").slice(2, 4);
  const [page, setPage] = useState(0);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [page]);

  const createUrl = () => {
    let url = "";

    if (module === "pyq") {
      url += "/previousyearques";
      if (subName && topicName) {
        url += `?subName=${subName}&topicName=${topicName}`;
      } else {
        url += `?year=${years}`;
        if (set) url += `&set=${set}`;
      }
    } else {
      url += `/practicalbookletques?subName=${subName}&topicName=${topicName}`;

      category === "basic"
        ? (url += "&category=BASIC")
        : (url += "&category=ADVANCE");
    }

    return url;
  };

  useEffect(() => {
    dispatch(getQuestionList(createUrl()));
  }, [subName, topicName, years, set, module, category]);

  const createTitle = () => {
    return location.pathname
      .split("/")
      .slice(2)
      .map((item) => {
        return item.length === 1
          ? `SET ${item}`
          : decodeURIComponent(item).toUpperCase();
      })
      .join(" | ");
  };
  return (
    <>
      <Helmet>
        <title>{createTitle() + " gateatzeal"}</title>
        <meta name="description" content={`${createTitle()}| practice questions | gate pyq | ${constTags}`} />
      </Helmet>
      <h2>{createTitle()}</h2>

      {questionList.count &&
        range(
          page * 10,
          Math.min((page + 1) * 10, questionList.items.length)
        ).map((pos) => {
          return (
            <>
              <SingleTestQuestion
                key={pos}
                data={questionList.items[pos]}
                discussBtn
              />

              {/* SquareAd5 */}
              {(pos === page * 10 + 2) && <GoogleAd slot={"5951937359"} currPath={location.pathname} />}
              {/* HorizontalAd4 */}
              {(pos === page * 10 + 5) && <GoogleAd slot={"5371221537"} currPath={location.pathname} />}
              {/* HorizontalAd5 */}
              {(pos === page * 10 + 8) && <GoogleAd slot={"7805813188"} currPath={location.pathname} />}

            </>
          );
        })}

      <div>
        <div className="pagination">
          {questionList.previous && (
            <Button
              onClick={() => {
                dispatch(clearQuestionList());
                setPage(0);
                dispatch(getQuestionList(questionList.previous));
              }}
            >
              <ArrowLeftIcon /> Previous set
            </Button>
          )}

          <div className="">
            <p>Current set Pages</p>
            {Array.from(
              Array(
                Math.ceil(Math.min(questionList.items.length, PAGE_SIZE) / 10)
              ).keys()
            ).map((pgn) => {
              return (
                <Button key={pgn} onClick={() => setPage(pgn)}>
                  {pgn + 1}
                </Button>
              );
            })}
          </div>
          {questionList.next && (
            <Button
              onClick={() => {
                dispatch(clearQuestionList());
                setPage(0);
                dispatch(getQuestionList(questionList.next));
              }}
            >
              Next set
              <ArrowRightIcon />
            </Button>
          )}
        </div>
        <div className="allSet">
          {questionList.count &&
            Array.from(
              Array(Math.ceil(questionList.count / PAGE_SIZE)),
              (_, index) => index + 1
            ).map((val, idx) => (
              <Button
                key={val}
                onClick={() => {
                  dispatch(getQuestionList(createUrl() + `&page=${val}`));
                }}
              >
                Set {val}
              </Button>
            ))}
        </div>
      </div>
    </>
  );
}

export default QuestionList;
