import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import "./style.css";

function Footer() {
  return (
    <div className="footer">
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={6} md={3}>
            <a href="https://gateatzeal.com">Gate@zeal.com</a>
          </Grid>
          <Grid item xs={6} md={3}>
            <a href="https://gateatzeal.com">Gate@zeal.com</a>
          </Grid>
          <Grid item xs={6} md={3}>
            <a href="https://gateatzeal.com">Gate@zeal.com</a>
          </Grid>
          <Grid item xs={6} md={3}>
            <a href="https://gateatzeal.com">Gate@zeal.com</a>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default Footer;
