import React, { useState } from "react";
import { IconButton, Tooltip } from "@mui/material";
import logo from "../../assets/images/logo.png";
import MenuIcon from "@mui/icons-material/Menu";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import HomeIcon from "@mui/icons-material/Home";
import "./style.css";
import { Link } from "react-router-dom";

function TopNav(props) {
  return (
    <div className={"topnav dark-bg"}>
      <div className="leftMenu">
        <IconButton onClick={() => props.toggleSidebar()}>
          <MenuIcon className={`dark-bg dark-text`} />
        </IconButton>

        <div className="logoWrapper">
          <Tooltip title="Home GateAtZeal">
            <Link to="/">
              <img src={logo} alt="GateAtZeal Logo" />
            </Link>
          </Tooltip>
        </div>
      </div>
      <div className="rightMenu">
        <IconButton className="homeButton">
          <Link to="/">
            <HomeIcon className={`dark-bg dark-text`} />
          </Link>
        </IconButton>

        <IconButton
          onClick={() => {
            props.setTheme(props.theme === "dark" ? "light" : "dark");
            localStorage.setItem(
              "theme",
              props.theme === "dark" ? "light" : "dark"
            );
          }}
        >
          {props.theme === "dark" ? (
            <LightModeOutlinedIcon className={`dark-bg dark-text`} />
          ) : (
            <DarkModeOutlinedIcon className={`dark-bg dark-text`} />
          )}
        </IconButton>
      </div>
    </div>
  );
}

export default TopNav;
