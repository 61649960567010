import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import { Button, Tooltip } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import TextField from "@mui/material/TextField";
// import GoogleAd from "../GoogleAd";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import "./style.css";
import { useLocation, useNavigate } from "react-router-dom";

function SingleTestQuestion(props) {
  const {
    Gate_Ques_Number,
    answer,
    marks,
    neg_marking,
    question_options,
    question_text,
    question_title,
    question_type,
    set_type,
    solution,
    tag,
    year,
  } = props.data;

  const [isCorrect, setIsCorrect] = useState("");
  const [userAnswerNAT, setUserAnswerNAT] = useState("");
  const [userAnswerMCQ_MSQ, setUserAnswerMCQ_MSQ] = useState(() => {
    return [false, false, false, false];
  });
  const [showSolution, setShowSolution] = useState(false);
  const location = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
      setIsCorrect("");
      setUserAnswerNAT("");
      setUserAnswerMCQ_MSQ(() => {
        return [false, false, false, false];
      });
      setShowSolution(false);
  }, [props.data.question_title]);

  const AccordingToQuestionType = (type) => {
    switch (type) {
      case "NAT":
        return (
          <div>
            <TextField
              id="standard-basic"
              label="Your Answer"
              variant="standard"
              color="warning"
              value={userAnswerNAT}
              onChange={(e) => setUserAnswerNAT(e.target.value)}
              focused
            />
          </div>
        );
      case "MCQ":
        return (
          <div className="optionWrapper">
            {Object.keys(question_options).map((opt, idx) => (
              <div key={idx}>
                <label className="questionOption">
                  <input
                    type="radio"
                    value={opt}
                    checked={userAnswerMCQ_MSQ[idx]}
                    onChange={(e) => {
                      let ans = [false, false, false, false];
                      ans[idx] = true;
                      setUserAnswerMCQ_MSQ([...ans]);
                    }}
                    name="answer"
                  />
                  {parse(question_options[opt])}
                </label>
              </div>
            ))}
          </div>
        );
      case "MSQ":
        return (
          <div className="optionWrapper">
            {Object.keys(question_options).map((opt, idx) => (
              <div key={idx}>
                <label className="questionOption">
                  <input
                    type="checkbox"
                    value={opt}
                    checked={userAnswerMCQ_MSQ[idx]}
                    onChange={() => {
                      let ans = [...userAnswerMCQ_MSQ];
                      ans[idx] = !ans[idx];
                      setUserAnswerMCQ_MSQ([...ans]);
                    }}
                  />
                  {parse(question_options[opt])}
                </label>
              </div>
            ))}
          </div>
        );

      default:
        return null;
    }
  };

  const checkAnswer = () => {
    if (userAnswerMCQ_MSQ.some((val) => val !== false) || userAnswerNAT) {
      setShowSolution(true);
      let ans = false;
      if (question_type === "NAT") {
        const [l, h] = answer.split("to");
        ans =
          parseFloat(l) <= parseFloat(userAnswerNAT) &&
          parseFloat(userAnswerNAT) <= parseFloat(h);
      } else {
        ans =
          ["A", "B", "C", "D"]
            .filter((val, idx) => userAnswerMCQ_MSQ[idx])
            .join(",") === answer;
      }
      setIsCorrect(ans);
    } else {
      alert("Mark answer");
    }
  };

  return (
    <div className="singleQuestion">
      <h3>
        <span>
          {isCorrect !== "" &&
            (isCorrect ? (
              <CheckCircleIcon className="checkIcon" color="success" />
            ) : (
              <CancelIcon className="checkIcon" color="error" />
            ))}
          {question_title}
        </span>
        {props.discussBtn && (
          <span className="discussBtn">
            <Tooltip title="Discuss Question">
              <OpenInNewIcon
                onClick={() => {
                  year
                    ? localStorage.setItem("discussQuestionModule", "pyq")
                    : localStorage.setItem("discussQuestionModule", "pm");
                  navigate(`/user/question/${question_title}`);
                }}
              />
            </Tooltip>
          </span>
        )}
      </h3>
      {marks && (
        <div>
          <small>
            Marks: <b>{marks}</b>
            {" , "}
          </small>
          <small className="textRed">
            Neg Marks: <b>{-neg_marking}</b>
          </small>
        </div>
      )}

      <hr />
      <div>{parse(question_text)}</div>
      {AccordingToQuestionType(question_type)}
      <div>
        <Button variant="outlined" color="warning" onClick={checkAnswer}>
          {"Check Answer"}
          {isCorrect !== "" &&
            (isCorrect ? (
              <CheckCircleIcon className="checkIcon" color="success" />
            ) : (
              <CancelIcon className="checkIcon" color="error" />
            ))}
        </Button>
      </div>
      {showSolution && (
        <div>
          <h5>Solution: </h5>
          {parse(solution)}
          {/* SquareAd3 */}
          {/* <GoogleAd slot={"6719020277"} currPath = {location.pathname} /> */}
        </div>
      )}

      <div className="tagWrapper">
        {year && <span className="tagItem">{`GATE ${year} `}</span>}
        {year && (
          <span className="tagItem">
            {set_type !== "na" && "SET " + set_type} {`Q${Gate_Ques_Number}`}
          </span>
        )}

        {tag &&
          tag.map((item) => (
            <span key={item} className="tagItem">
              {item}
            </span>
          ))}
      </div>
    </div>
  );
}

export default SingleTestQuestion;
