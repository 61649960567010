import axiosInstance from "../axios";
import { notesListConstants } from "../utilities/constants";

export const getNotesList = (subName, topicName, url = null) => {
  return async (dispatch) => {
    dispatch({
      type: notesListConstants.GET_NOTES_LIST_REQUEST,
    });

    try {
      let res;
      if (url) {
        res = await axiosInstance.get(url);
      } else {
        res = await axiosInstance.get(
          `/notes?subName=${subName}&topicName=${topicName}`
        );
      }

      if (res.status === 200) {
        dispatch({
          type: notesListConstants.GET_NOTES_LIST_SUCCESS,
          payload: {
            count: res.data.count,
            previous: res.data.previous,
            next: res.data.next,
            items: res.data.results,
          },
        });
      } else {
        dispatch({
          type: notesListConstants.GET_NOTES_LIST_FAILURE,
          payload: {
            error: "Failed to load notes list" + res.status,
          },
        });
      }
    } catch (e) {
      dispatch({
        type: notesListConstants.GET_NOTES_LIST_FAILURE,
        payload: {
          error: e.message,
        },
      });
    }
  };
};
