import { discussQuestionConstants } from "../utilities/constants";

const initialState = {
  item: {},
  loading: false,
  error: "",
};

const discussQuestionReducer = (state = initialState, action) => {
  switch (action.type) {
    case discussQuestionConstants.GET_QUESTION_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case discussQuestionConstants.GET_QUESTION_SUCCESS:
      state = {
        ...state,
        loading: false,
        item: action.payload.item,
      };
      break;
    case discussQuestionConstants.GET_QUESTION_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error,
      };
      break;
    default:
      break;
  }
  return state;
};

export default discussQuestionReducer;
