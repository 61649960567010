import axios from "axios";
import { apiBaseUrl } from "../utilities/urlConfig";

const token = localStorage.getItem("token");

const axiosInstance = axios.create({
  baseURL: apiBaseUrl,
  headers: {
    Authorization: token ? `jwt ${token}` : null,
  },
});

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
