import axiosInstance from "../axios";
import { discussQuestionConstants } from "../utilities/constants";

export const getQuestion = (title) => {
  return async (dispatch) => {
    dispatch({
      type: discussQuestionConstants.GET_QUESTION_REQUEST,
    });

    try {
      let module = localStorage.getItem("discussQuestionModule");
      let res;
      if (module === "pyq") {
        res = await axiosInstance.get(`/previousyearques?title=${title}`);
      } else {
        res = await axiosInstance.get(`/practicalbookletques?title=${title}`);
      }

      if (res.status === 200) {
        if (res.data.results) {
          dispatch({
            type: discussQuestionConstants.GET_QUESTION_SUCCESS,
            payload: {
              item: res.data.results[0],
            },
          });
        } else {
          dispatch({
            type: discussQuestionConstants.GET_QUESTION_FAILURE,
            payload: {
              error: "No data found or failed to load question",
            },
          });
        }
      } else {
        dispatch({
          type: discussQuestionConstants.GET_QUESTION_FAILURE,
          payload: {
            error: "No data found or failed to load question",
          },
        });
      }
    } catch (e) {
      dispatch({
        type: discussQuestionConstants.GET_QUESTION_FAILURE,
        payload: {
          error: e.message(),
        },
      });
    }
  };
};
