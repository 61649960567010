import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAdList, getUsefulLinks } from "../../actions";
import "./style.css";
import GoogleAd from "../GoogleAd";
import { range } from "../../utilities/helperFunctions";
import { useLocation } from "react-router-dom";

function AdArea() {
  const dispatch = useDispatch();
  const adArea = useSelector((state) => state.adArea);
  const usefulLinks = useSelector((state) => state.usefulLinks);
  const location = useLocation();
  const verticalAdSlots = [
    "8142399697",
    "9282546383",
    "1735190868",
    "2930489513",
    "6464811352",
  ];

  useEffect(() => {
    dispatch(getAdList());
    dispatch(getUsefulLinks());
  }, [dispatch]);

  const imgComponent = (item) => {
    return (
      <a href={item.text} target="_blank" key={item.image} rel="noreferrer">
        <div className="imgWrapper">
          <img src={item.image} alt={item.image} loading="lazy" />
        </div>
      </a>
    );
  };

  const usefulLinkComponent = (item) => {
    return (
      <li key={item.text}>
        <a href={item.link} target="_blank" rel="noreferrer">
          {item.text}
        </a>
      </li>
    );
  };

  return (
    <div className="zeal_adArea">
      <div>
        <div style={{ padding: "1rem" }}>
          &copy; {new Date().getFullYear()} GateAtZeal
        </div>

        {/* VerticalAd1 */}
        <GoogleAd slot={verticalAdSlots[0]} currPath={location.pathname} />

        {adArea.adList
          .slice(0, Math.min(3, adArea.adList.length))
          .map((item) => {
            return imgComponent(item);
          })}

        {usefulLinks.list.length > 0 ? (
          <div>
            <h3>Useful links</h3>
            <ul>
              {usefulLinks.list
                .slice(0, Math.min(5, usefulLinks.list.length))
                .map((item) => {
                  return usefulLinkComponent(item);
                })}
            </ul>
          </div>
        ) : (
          ""
        )}

        {/* VerticalAd2 */}
        <GoogleAd slot={verticalAdSlots[1]} currPath={location.pathname} />

        {adArea.adList
          .slice(3, Math.min(6, adArea.adList.length))
          .map((item) => {
            return imgComponent(item);
          })}

        {usefulLinks.list.length > 5 ? (
          <div>
            <h3>Useful links</h3>
            <ul>
              {usefulLinks.list
                .slice(5, Math.min(10, usefulLinks.list.length))
                .map((item) => {
                  return usefulLinkComponent(item);
                })}
            </ul>
          </div>
        ) : (
          ""
        )}

        {/* VerticalAd3 */}
        <GoogleAd slot={verticalAdSlots[2]} currPath={location.pathname} />

        {adArea.adList
          .slice(6, Math.min(9, adArea.adList.length))
          .map((item) => {
            return imgComponent(item);
          })}

        {usefulLinks.list.length > 10 ? (
          <div>
            <h3>Useful links</h3>
            <ul>
              {usefulLinks.list
                .slice(10, usefulLinks.list.length)
                .map((item) => {
                  return usefulLinkComponent(item);
                })}
            </ul>
          </div>
        ) : (
          ""
        )}
      </div>

      {/* verticalAd4 ... verticalAd10 */}
      {range(3, Math.min(10, verticalAdSlots.length)).map((idx) => {
        <GoogleAd
          key={idx}
          slot={verticalAdSlots[idx]}
          currPath={location.pathname}
        />;
      })}
    </div>
  );
}

export default AdArea;
