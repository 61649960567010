import { notesListConstants } from "../utilities/constants";

const initialState = {
  count: 0,
  next: null,
  previous: null,
  items: [],
  loading: false,
  error: "",
};

const sortByOrder = (arr) => {
  arr.sort((a, b) => {
    if (a.order < b.order) return -1;
    if (a.order > b.order) return 1;
    return 0;
  });
  return arr;
};

const notesListReducer = (state = initialState, action) => {
  switch (action.type) {
    case notesListConstants.GET_NOTES_LIST_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case notesListConstants.GET_NOTES_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        count: action.payload.count,
        next: action.payload.next,
        previous: action.payload.previous,
        items: sortByOrder(action.payload.items),
      };

      break;
    case notesListConstants.GET_NOTES_LIST_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error,
      };
      break;
    default:
      break;
  }
  return state;
};

export default notesListReducer;
