import React from "react";
import { useSelector } from "react-redux";
import ScaleLoader from "react-spinners/ScaleLoader";
import { css } from "@emotion/react";
// import "./style.css";

const loaderCss = css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 12;
  text-align: center;
  padding-top: 50vh;
`;

function Loader() {
  const discussQuestion = useSelector((state) => state.discussQuestion);
  const notesList = useSelector((state) => state.notesList);
  const notesPreview = useSelector((state) => state.notesPreview);
  const questionList = useSelector((state) => state.questionList);
  const sidenav = useSelector((state) => state.sidenav);

  return (
    <ScaleLoader
      css={loaderCss}
      color="silver"
      height={40}
      width={4}
      margin={3}
      loading={
        discussQuestion.loading ||
        notesList.loading ||
        notesPreview.loading ||
        questionList.loading ||
        sidenav.loading
      }
    />
  );
}

export default Loader;
