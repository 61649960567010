import axiosInstance from "../axios";
import { usefulLinkConstants } from "../utilities/constants";

export const getUsefulLinks = () => {
  return async (dispatch) => {
    dispatch({
      type: usefulLinkConstants.FETCH_LINK_REQUEST,
    });

    try {
      const res = await axiosInstance.get("/usefullink/");

      if (res.status === 200) {
        dispatch({
          type: usefulLinkConstants.FETCH_LINK_SUCCESS,
          payload: {
            count: res.data.count,
            list: res.data.results,
            next: res.data.next,
            previous: res.data.previous,
          },
        });
      } else {
        dispatch({
          type: usefulLinkConstants.FETCH_LINK_FAILURE,
          payload: {
            error: "Failed to fetch links. " + res.status,
          },
        });
      }
    } catch (e) {
      dispatch({
        type: usefulLinkConstants.FETCH_LINK_FAILURE,
        payload: {
          error: e.message,
        },
      });
    }
  };
};
