import { combineReducers } from "redux";
import sidenavReducer from "./sidenav.reducer";
import notesListReducer from "./notesList.reducer";
import notesPreviewReducer from "./notesPreview.reducer";
import questionListReducer from "./questionList.reducer";
import discussQuestionReducer from "./discussQuestion.reducer";
import adAreaReducer from "./adArea.reducer";
import usefulLinksReducer from "./usefulLinks.reducer";

const rootReducer = combineReducers({
  sidenav: sidenavReducer,
  notesList: notesListReducer,
  notesPreview: notesPreviewReducer,
  questionList: questionListReducer,
  discussQuestion: discussQuestionReducer,
  adArea: adAreaReducer,
  usefulLinks: usefulLinksReducer,
});

export default rootReducer;
