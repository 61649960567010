import { sidenavConstants } from "../utilities/constants";
const initialState = {
  items: [],
  loading: false,
  error: "",
};

const sidenavReducer = (state = initialState, action) => {
  switch (action.type) {
    case sidenavConstants.FETCH_NAVLIST_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case sidenavConstants.FETCH_NAVLIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        items: action.payload.items,
      };
      break;
    case sidenavConstants.FETCH_NAVLIST_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error,
      };
      break;

    default:
      break;
  }
  return state;
};


export default sidenavReducer;