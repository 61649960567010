import { sidenavConstants } from "../utilities/constants";
import axiosInstance from "../axios";

export const getSideNavList = () => {
  return async (dispatch) => {
    dispatch({
      type: sidenavConstants.FETCH_NAVLIST_REQUEST,
    });

    try {
      const res = await axiosInstance.get("/sidebar/");

      if (res.status === 200) {
        dispatch({
          type: sidenavConstants.FETCH_NAVLIST_SUCCESS,
          payload: {
            items: res.data,
          },
        });
      } else {
        dispatch({
          type: sidenavConstants.FETCH_NAVLIST_FAILURE,
          payload: { error: "Failed to get sidenav" },
        });
      }
    } catch (e) {
      dispatch({
        type: sidenavConstants.FETCH_NAVLIST_FAILURE,
        payload: { error: e },
      });
    }
  };
};
