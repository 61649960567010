import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import DiscussQuestion from "./containers/DiscussQuestion";
import NotFound404 from "./components/NotFound404";
import HomePage from "./containers/HomePage";
import NotesList from "./containers/NotesList";
import NotesPreview from "./containers/NotesPreview";
import QuestionList from "./containers/QuestionList";
import Layout from "./components/Layout";

function App() {
  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/user/notes">
            <Route path=":subName/:topicName" element={<NotesList />} />
            <Route path=":subName" element={<HomePage />} />
            <Route path="" element={<HomePage />} />
          </Route>
          <Route path="/user/notes/preview">
            <Route
              path=":subName/:topicName/:noteTitle"
              element={<NotesPreview />}
            />
            <Route path=":subName/:topicName" element={<HomePage />} />
            <Route path=":subName" element={<HomePage />} />
            <Route path="" element={<HomePage />} />
          </Route>
          <Route path="/user/pm/basic">
            <Route path=":subName/:topicName" element={<QuestionList />} />
            <Route path=":subName" element={<HomePage />} />
            <Route path="" element={<HomePage />} />
          </Route>
          <Route path="/user/pm/advance">
            <Route path=":subName/:topicName" element={<QuestionList />} />
            <Route path=":subName" element={<HomePage />} />
            <Route path="" element={<HomePage />} />
          </Route>
          <Route path="/user/pyq/subject-wise">
            <Route path=":subName/:topicName" element={<QuestionList />} />
            <Route path=":subName" element={<HomePage />} />
            <Route path="" element={<HomePage />} />
          </Route>
          <Route path="/user/pyq/year-wise">
            <Route path=":years/:set" element={<QuestionList />} />
            <Route path=":years" element={<QuestionList />} />
            <Route path="" element={<HomePage />} />
          </Route>
          <Route
            path="/user/question/:quesTitle"
            element={<DiscussQuestion />}
          />
          <Route path="/*" element={<NotFound404 />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
