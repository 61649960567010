import React, { useCallback, useEffect, useState } from "react";
import AdArea from "../AdArea";
import Footer from "../Footer";
import Loader from "../Loader";
import SideNav from "../SideNav";
import TopNav from "../TopNav";
import GoogleAd from "../GoogleAd";
import "./style.css";
import { useLocation } from "react-router-dom";

function Layout(props) {
  const [showSidebar, setShowSidebar] = useState(true);
  const [mobileView, setMobileView] = useState(true);
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "dark");
  const location = useLocation();

  const handleResize = useCallback(() => {
    if (document.documentElement.clientWidth < 850) {
      setShowSidebar(false);
      setMobileView(true);
    } else {
      setShowSidebar(true);
      setMobileView(false);
    }
  }, []);

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  return (
    <>
      <Loader />
      <TopNav toggleSidebar={toggleSidebar} theme={theme} setTheme={setTheme} />
      <SideNav
        mobileView={mobileView}
        setShowSidebar={setShowSidebar}
        className={
          (showSidebar ? "displayBlock " : "displayNone ") +
          `${theme}-bg-sec ${theme}-text`
        }
        theme={theme}
      />
      <div className={`dynamicArea ${theme}`}>
        <div
          className={`content ${showSidebar ? "marginLeft300" : "marginLeft0"}`}
        >
          {props.children}
          {/* SquareAd1 */}
          <GoogleAd slot={"3081644707"} currPath = {location.pathname} />
          {/* SquareAd2 */}
          <GoogleAd slot={"4830427370"} currPath = {location.pathname} />
        </div>
        <AdArea />
      </div>
    </>
  );
}

export default Layout;
