import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import Layout from "../../components/Layout";
import parse from "html-react-parser";
import "./style.css";
import { getNotesList, getSingleNote, setNoteForPreview } from "../../actions";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import Helmet from "react-helmet";
import GoogleAd from "../../components/GoogleAd";
import { constTags } from "../../utilities/constants";

function NotesPreview() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { subName, topicName, noteTitle } = useParams();
  const notesList = useSelector((state) => state.notesList);
  const notesPreview = useSelector((state) => state.notesPreview);

  const location = useLocation();

  const onLoadTarget = () => {
    dispatch(getNotesList(subName, topicName));
  };
  useEffect(() => {
    notesPreview.item &&
      Object.keys(notesPreview.item).length === 0 &&
      dispatch(getSingleNote(noteTitle));
  }, [notesPreview.item, noteTitle, dispatch]);

  useEffect(() => {
    (function () {
      // DON'T EDIT BELOW THIS LINE
      var d = document,
        s = d.createElement("script");
      s.src = "https://gateatzeal-1.disqus.com/embed.js";
      s.setAttribute("data-timestamp", +new Date());
      (d.head || d.body).appendChild(s);
    })();
    if (document.readyState === "complete") {
      onLoadTarget();
    } else {
      window.addEventListener("load", onLoadTarget);

      return () => {
        window.removeEventListener("load", onLoadTarget);
      };
    }
  }, []);

  const { title, subtopic, description, category, tag } =
    notesPreview.item || {};

  const PaginationButton = () => {
    return (
      <div className="paginationBtnWrapper">
        <Button
          variant="outlined"
          disabled={notesPreview.idx <= 0}
          onClick={() => {
            dispatch(
              setNoteForPreview(
                notesList.items[notesPreview.idx - 1],
                notesPreview.idx - 1
              )
            );
            navigate(
              `/user/notes/preview/${subName}/${topicName}/${notesList.items[notesPreview.idx - 1].title
              }`
            );
          }}
        >
          <NavigateBeforeIcon /> Prev
        </Button>
        <Button
          variant="outlined"
          disabled={notesPreview.idx >= notesList.items.length - 1}
          onClick={() => {
            dispatch(
              setNoteForPreview(
                notesList.items[notesPreview.idx + 1],
                notesPreview.idx + 1
              )
            );
            navigate(
              `/user/notes/preview/${subName}/${topicName}/${notesList.items[notesPreview.idx + 1].title
              }`
            );
          }}
        >
          Next <NavigateNextIcon />
        </Button>
      </div>
    );
  };

  return (
    <>
      <Helmet>
        <title>
          {title
            ? `${title} ${subName} ${topicName} GateAtZeal Notes`
            : "GateAtZeal Notes"}
        </title>
        <meta name="description" content={`${title} | ${subName}| ${topicName} | GateAtZeal Notes | FreeAtZeal notes | ${constTags}}`} />
      </Helmet>
      {notesPreview.item && Object.keys(notesPreview.item).length > 0 ? (
        <div>
          <h5 style={{ marginBottom: 0 }}>{subtopic && subtopic}</h5>
          <h1 style={{ marginTop: 0 }}>{title && title}</h1>
          <div className="tagWrapper">
            <span>Tags: </span>
            {tag &&
              tag.map((_tag) => {
                return (
                  <span key={_tag} className="tag">
                    {_tag}
                  </span>
                );
              })}
          </div>
          <p>
            Important for{" "}
            <span className="specialTag">{category && category}</span>
          </p>
          {/* SquareAd3 */}
          <GoogleAd slot={"6719020277"} currPath={location.pathname} />

          {description && parse(description)}

          {/* SquareAd4 */}
          <GoogleAd slot={"8578100691"} currPath={location.pathname} />
        </div>
      ) : (
        <p>No article found</p>
      )}

      {PaginationButton()}
      <div id="disqus_thread"></div>
    </>
  );
}

export default NotesPreview;
