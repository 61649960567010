import { questionListConstants } from "../utilities/constants";

const initialState = {
  count: 0,
  next: null,
  previous: null,
  items: [],
  error: "",
  loading: false,
};

const questionListReducer = (state = initialState, action) => {
  switch (action.type) {
    case questionListConstants.GET_QUESTION_LIST_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case questionListConstants.GET_QUESTION_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        items: action.payload.items,
        previous: action.payload.previous,
        next: action.payload.next,
        count: action.payload.count,
      };
      break;
    case questionListConstants.GET_QUESTION_LIST_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error,
      };
      break;
    case questionListConstants.CLEAR_REDUCER:
      state = initialState;
      break;
    default:
      break;
  }
  return state;
};

export default questionListReducer;
