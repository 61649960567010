import { adConstants } from "../utilities/constants";

const initalState = {
  count: 0,
  next: null,
  previous: null,
  adList: [],
  error: "",
  loading: false,
};

const adAreaReducer = (state = initalState, action) => {
  switch (action.type) {
    case adConstants.FETCH_AD_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case adConstants.FETCH_AD_SUCCESS:
      state = {
        ...state,
        loading: false,
        count: action.payload.count,
        adList: action.payload.adList.sort(function (a, b) {
          return new Date(b.created_at) - new Date(a.created_at);
        }),
        next: action.payload.next,
        previous: action.payload.previous,
      };
      break;
    case adConstants.FETCH_AD_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error,
      };
      break;

    default:
      break;
  }
  return state;
};

export default adAreaReducer;
