import { usefulLinkConstants } from "../utilities/constants";
const initialState = {
  count: 0,
  next: null,
  previous: null,
  list: [],
  error: "",
  loading: false,
};

const usefulLinksReducer = (state = initialState, action) => {
  switch (action.type) {
    case usefulLinkConstants.FETCH_LINK_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;

    case usefulLinkConstants.FETCH_LINK_SUCCESS:
      state = {
        ...state,
        loading: false,
        count: action.payload.count,
        list: action.payload.list.sort(function (a, b) {
          return new Date(b.created_at) - new Date(a.created_at);
        }),
        next: action.payload.next,
        previous: action.payload.previous,
      };
      break;
    case usefulLinkConstants.FETCH_LINK_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error,
      };
      break;

    default:
      break;
  }
  return state;
};

export default usefulLinksReducer;
