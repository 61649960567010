import React, { useEffect } from "react";
// import { useLocation } from "react-router-dom";

function GoogleAd(props) {
  // const location = useLocation();
  const { slot, currPath } = props;

  useEffect(() => {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, [currPath]);

  return (
    <ins
      className="adsbygoogle"
      key={currPath}
      style={{ display: "block" }}
      data-full-width-responsive="true"
      data-ad-format="auto"
      data-ad-client="ca-pub-9922159991704571"
      data-ad-slot={slot}
    ></ins>
  );
}

export default GoogleAd;
